export enum StorageKeys {
  SelfCareToken = 'authSelfcareToken',
  VisitorId = 'visitorId',
  AccountId = 'accountId',
  LineCheckAddress = 'lineCheckData',
  LineCheckAddressPos = 'lineCheckDataPos',
  Translations = 'translations',
  PrismicTranslations = 'prismicTranslations',
  LocalTranslations = 'localTranslations',
  PromotionCodes = 'promotionCodesSetAsDefault',
  FeatureFlag = 'featureFlags',
  Pos = 'authPosToken',
  CurrentLanguage = 'currentLanguage',
  CustomerRefId = 'customerRefId',
  CustomerUsername = 'customerUsername',
  SMARTWATCH_DATA = 'smartwatch_data',
}
