import { inject, Injectable } from '@angular/core';
import { PrismicDocument } from '@prismicio/client';
import { Observable, of } from 'rxjs';
import { lang } from 'interfaces';
import { LanguageService } from 'language';
import { CmsService } from 'prismic';
import { SupportArticle } from './support-types';
import { urlifyTitle } from './urlify-title';

@Injectable({
  providedIn: 'root',
})
export class ArticleService {
  cmsService = inject(CmsService);
  lang = inject(LanguageService);

  public getArticlePath(article: PrismicDocument<SupportArticle>, language?: lang): Observable<string> {
    if (!language) language = this.lang.current;
    if (article.lang !== this.cmsService.languages[language]) {
      const correctLangId = article.alternate_languages.find(
        lang => lang.lang === this.cmsService.languages[language]
      )?.id;
      return of(`/${language}/${correctLangId}`);
    }

    return of(`/${language}/${article.id}-${urlifyTitle(article.data.title)}`);
  }
}
