@if (categories$ | async; as categories) {
  @if (categories?.length) {
    <div class="grid grid-cols-2 xl:grid-cols-3 justify-center gap-4 md:gap-10">
      @for (category of categories; track category.id) {
        <lib-action-card
          [link]="transformCardLink(category)"
          [title]="category.data.title"
          [icon]="
            category.data.home_page_icon_key ? 'fontawesome/custom-gradient/' + category.data.home_page_icon_key : null
          "
          class="grid min-h-32 text-center"
        />
      }
    </div>
  }
} @else {
  <ngx-skeleton-loader
    class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 justify-center gap-10 [&_.skeleton-loader]:!h-28 [&_.skeleton-loader]:md:!h-56"
    count="3"
  />
}
