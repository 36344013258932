import { DOCUMENT } from '@angular/common';
import { inject, Injectable, signal } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { BehaviorSubject, filter, Observable } from 'rxjs';
import { lang } from 'interfaces';
import { BrowserService, FALLBACK_LANGUAGE, StorageKeys, StorageService } from 'utils';

type LanguageFull = {
  [key in lang]: string;
};

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private router = inject(Router);
  private browserService = inject(BrowserService);
  private storageService = inject(StorageService);
  readonly doc = inject<Document>(DOCUMENT);
  protected fallbackLang = inject<lang>(FALLBACK_LANGUAGE);
  private _current = new BehaviorSubject<lang>(undefined);
  current = this.fallbackLang;
  langs: lang[] = ['de', 'en', 'fr', 'it'];
  langsFull: LanguageFull = {
    de: 'Deutsch',
    en: 'English',
    fr: 'Français',
    it: 'Italiano',
  };
  onLangChange: Observable<lang> = this._current.asObservable();
  currentLanguage = signal<lang>(this.current);

  constructor() {
    this.setDefaultLanguage();
    this.updateLanguageBasedOnNavigation();
  }

  private setDefaultLanguage() {
    const languageFromStorage = this.storageService.get<lang>(StorageKeys.CurrentLanguage);
    if (languageFromStorage) {
      this.setLanguage(languageFromStorage);
    } else {
      const userLanguage = this.browserService.getDefaultLanguage();

      if (this.langs.includes(userLanguage as lang)) {
        this.setLanguage(userLanguage as lang);
      } else {
        // Fall back to German if the user language is not supported (e.g. `es`)
        this.setLanguage(this.fallbackLang);
      }
    }
  }

  private updateLanguageBasedOnNavigation() {
    this.router.events
      .pipe(filter(route => route instanceof NavigationStart || route instanceof NavigationEnd))
      .subscribe((val: NavigationEnd | NavigationStart) => {
        let lang: lang;

        if (val.url !== undefined) {
          lang = <lang>val.url.split('/')[1];
          lang = <lang>lang?.split('#')[0];
          lang = <lang>lang?.split('?')[0];
        }

        if (!this.langs.includes(lang)) return;

        if (val instanceof NavigationStart) {
          // So that resolvers / guards already have the correct new language
          this.setLanguage(lang);
          this._current.next(this.current);
          this.currentLanguage.set(this.current);
        }

        if (val instanceof NavigationEnd) {
          this.doc.querySelector('html').setAttribute('lang', this.current);
        }
      });
  }

  public setLanguage(language: lang) {
    this.current = language;
    this.currentLanguage.set(language);
    this.storageService.set<lang>(StorageKeys.CurrentLanguage, language);
  }
}
