<lib-link
  textCssClass="flex h-full"
  [link]="link"
  [class]="'border border-[--color-gray-4] sales-box flex justify-center ' + additionalClass"
>
  <div class="flex flex-col items-center p-4 md:p-8 gradient-icon active justify-center" [ngClass]="brandService.brand">
    @if (icon) {
      <lib-svg class="mb-2 [&_svg]:w-8 [&_svg]:h-8" [svg]="icon" [ngClass]="icon"></lib-svg>
    }
    @if (title) {
      <p class="md:text-lg font-bold">{{ title }}</p>
    }
    @if (description) {
      <p class="text-base text-[--color-gray-6] text-center">{{ description }}</p>
    }
  </div>
</lib-link>
