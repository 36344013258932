<lib-menu-global />
<lib-navigation [logoUrl]="logoUrl()" />
<lib-curved-box curve="bottom" [dontHideOverflow]="true">
  <div class="container pt-10 px-5 flex justify-center">
    <div class="max-w-3xl text-center">
      <h1 class="text-2xl md:text-6xl">{{ ['support', 'title'] | translate }}</h1>
      <h2 class="text-md mb-10">
        {{ ['support', 'sub_title'] | translate }}
      </h2>
      <app-search></app-search>
    </div>
  </div>
</lib-curved-box>
<main class="container my-6 px-5 xl:px-56">
  <router-outlet />
</main>
<lib-footer />
