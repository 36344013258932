import { inject, Injectable } from '@angular/core';
import {
  AnyRegularField,
  FilledContentRelationshipField,
  FilledLinkToWebField,
  ImageField,
  Slice,
} from '@prismicio/client';
import { MenuItem, SimpleMenuItem } from 'interfaces';
import { LanguageService } from 'language';
import {
  CmsService,
  NavigationDocumentDataBodyMenuItemSlice,
  NavigationDocumentDataBodyMenuItemSliceItem,
  NavigationDocumentDataBodySimpleMenuItemSlice,
  prismicToHtmlString,
  ProductBoxDocument,
} from 'prismic';
import { ProductService } from 'product';
import { CachedDataLoaderService } from 'utils';
import { MenuItemWithDropdown } from './menu.interface';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  cms = inject(CmsService);
  cachedDataLoader = inject(CachedDataLoaderService);
  lang = inject(LanguageService);
  private productsService = inject(ProductService);
  customization: { logo: ImageField; themeColor: string };

  async getMainNav() {
    return (await this.getAll()).mainNav;
  }

  async getPhoneNumber() {
    return {
      phoneNumber: (await this.getAll()).phoneNumber,
      phoneNumberLink: (await this.getAll()).phoneNumberLink,
      phoneCallPrice: (await this.getAll()).phoneCallPrice,
    };
  }

  async getLogo() {
    return (await this.getCustomTheme()).logo;
  }

  async getThemeColor() {
    return (await this.getCustomTheme()).themeColor;
  }

  private async getAll() {
    return await this.cachedDataLoader.get('navigation_' + this.lang.current, () => this.loadNavigationItems());
  }

  private async getCustomTheme() {
    return await this.cachedDataLoader.get('custom_theme_' + this.lang.current, () => this.loadCustomTheme());
  }

  private async getPrismicNavigationResponse() {
    return await this.cachedDataLoader.get('nav_response_' + this.lang.current, () => this.loadNavigationResponse());
  }

  private async loadNavigationResponse() {
    const fields = ['id', 'product_type', 'product_details_link', 'product_details_link_label', 'cta_button_label'].map(
      value => `product_box.${value}`
    );

    return await this.cms.getByType('navigation', undefined, undefined, undefined, fields);
  }

  private async loadCustomTheme() {
    const resp = await this.getPrismicNavigationResponse();

    const result = resp?.[0]?.data as {
      logo: ImageField;
      color_hex: string;
    };

    let logo: ImageField;
    let themeColor: string;

    if (result.logo) {
      logo = result.logo;
    }
    if (result.color_hex) {
      themeColor = result.color_hex;
    }

    return {
      logo,
      themeColor,
    };
  }

  private async loadNavigationItems() {
    const resp = await this.getPrismicNavigationResponse();

    const result = resp?.[0]?.data as {
      phone_number: string;
      phone_number_link: FilledLinkToWebField;
      phone_call_price: string;
      body: Slice<string, Record<string, AnyRegularField>, Record<string, AnyRegularField>>[];
    };
    // result.selfcare_menu_items}
    // result.selfcare_dropdown_menu_items

    let phoneNumber: string | undefined;
    let phoneNumberLink: FilledLinkToWebField | undefined;
    let phoneCallPrice: string | undefined;

    if (result.phone_number) {
      phoneNumber = result.phone_number;
    }
    if (result.phone_number_link?.url) {
      phoneNumberLink = result.phone_number_link;
    }
    if (result.phone_call_price) {
      phoneCallPrice = result.phone_call_price;
    }

    return {
      mainNav: await this.convertMainNavigationItems(result.body),
      phoneNumber,
      phoneNumberLink,
      phoneCallPrice,
      selfcareNav: {},
      selfcareDropdown: {},
    };
  }

  async getCustomization() {
    const [logo, themeColor] = await Promise.all([this.getLogo(), this.getThemeColor()]);

    this.customization = { logo, themeColor };
  }

  private async convertMainNavigationItems(navigationData: Slice[]): Promise<MenuItem[]> {
    const promises = navigationData.map(async slice => {
      if (slice.slice_type === 'menu_item')
        return this.convertMenuItemWithDropdown(slice as NavigationDocumentDataBodyMenuItemSlice);
      if (slice.slice_type === 'simple_menu_item')
        return this.convertSimpleMenuItem(slice as NavigationDocumentDataBodySimpleMenuItemSlice);
    });
    return Promise.all(promises);
  }

  private async convertMenuItemWithDropdown(slice: NavigationDocumentDataBodyMenuItemSlice) {
    const item = new MenuItemWithDropdown(slice);
    if ((slice.primary.product as FilledContentRelationshipField)?.id) {
      item.product = await this.productsService.convertProductBox(
        (await this.cms.getById((slice.primary.product as FilledContentRelationshipField).id)) as ProductBoxDocument
      );
    }
    item.title = slice.primary.title;
    item.secondLevelEntries = slice.items.map((s: NavigationDocumentDataBodyMenuItemSliceItem) => {
      return {
        cta: s.cta,
        image: s.image,
        title: s.title,
        text: prismicToHtmlString(s.text),
        link: s.link,
      };
    });
    return item;
  }

  private async convertSimpleMenuItem(slice: NavigationDocumentDataBodySimpleMenuItemSlice) {
    const item = new SimpleMenuItem(slice);
    item.title = slice.primary.title;
    item.link = slice.primary.link;
    return item;
  }
}
