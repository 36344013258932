@if (account()) {
  <lib-menu
    [label]="account().ownerDetails.firstName"
    [collapseOnMobile]="collapseOnMobile"
    [outlined]="true"
    icon="fontawesome/user"
    iconClass="normal-plus"
    data-testid="account-button"
  >
    <lib-menu-item [menuItemLink]="{ rawUrl: this.languageService.current + '/account' }">
      <span class="font-bold"> {{ brand.isYallo() ? 'My yallo' : 'My Lebara' }}</span>
    </lib-menu-item>
    <lib-menu-item>
      <span class="font-bold" (click)="logout()">{{ ['dropdown', 'logout'] | translate }}</span>
    </lib-menu-item>
  </lib-menu>
} @else {
  <lib-link data-testid="nav-login-button" [link]="loginLink()" class="flex items-center justify-evenly">
    <div
      class="round-box h-12 w-12 md:w-auto md:max-w-[10rem] xl:max-w-[8rem] flex justify-center items-center xl:mr-0 px-2"
    >
      <lib-svg class="white md:mr-1 normal-plus hidden md:block" svg="fontawesome/user"></lib-svg>
      <p class="text-white font-bold text-sm xl:text-base">Login</p>
    </div>
  </lib-link>
}
