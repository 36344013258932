import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { RedirectService } from 'redirect';
import { routeSnapshotToString } from 'utils';
import { LanguageService } from './language.service';

@Injectable({
  providedIn: 'root',
})
/**
 * If there is no language parameter it redirects to the current language versionlanguage version
 */
export class BaseLangGuard implements CanActivate {
  private languageService = inject(LanguageService);
  private redirectService = inject(RedirectService);

  canActivate(next: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    // If the language is not included, then we redirect to the correct language version
    return this.redirectService.redirectTo(
      `/${this.languageService.current}/${routeSnapshotToString(next, false)}`,
      next.queryParams
    );
  }
}
