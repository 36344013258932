import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, Input, signal } from '@angular/core';
import { LinkField, LinkType } from '@prismicio/client';
import { SelfcareService } from '@yol-digital/ms-client';
import { catchError, EMPTY } from 'rxjs';
import { AuthService, UserService } from 'auth-data-access';
import { BrandService } from 'brand';
import { SvgComponent } from 'icon';
import { LanguageService } from 'language';
import { LinkComponent } from 'link';
import { MenuComponent, MenuItemComponent } from 'menu';
import { ToastService } from 'toast';
import { TranslatePipe, TranslateService } from 'translate';
import { BrowserService, ENVIRONMENT_URLS_CONFIG_TOKEN, EnvironmentUrlsConfig } from 'utils';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lib-dropdown-menu',
  standalone: true,
  imports: [MenuItemComponent, MenuComponent, CommonModule, SvgComponent, LinkComponent, TranslatePipe],
  templateUrl: './dropdown-menu.component.html',
})
export class DropdownMenuComponent {
  private user = inject(UserService);
  private authService = inject(AuthService);
  lang = inject(LanguageService);
  brand = inject(BrandService);
  private translateService = inject(TranslateService);
  private toastService = inject(ToastService);
  private browserService = inject(BrowserService);
  languageService = inject(LanguageService);
  private config = inject<EnvironmentUrlsConfig>(ENVIRONMENT_URLS_CONFIG_TOKEN);
  account = signal<SelfcareService.AccountResp>(null);
  readonly LinkType = LinkType;
  @Input() collapseOnMobile = false;

  constructor() {
    this.user.getAccount().subscribe(account => {
      this.account.set(account);
    });
  }

  logout(): void {
    this.authService
      .logout()
      .pipe(
        catchError(err => {
          if (!err?.wasCaught) {
            console.error(err);
            this.toastService.add(this.translateService.getTranslation(['login', 'error', 'generic']), false);
          }
          return EMPTY;
        })
      )
      .subscribe(() => {
        this.account.set(null);
      });
  }

  loginLink = computed<LinkField>(() => {
    if (!this.config.websiteUrl || this.config.websiteUrl === this.browserService.getOrigin())
      return { link_type: LinkType.Document, slug: `auth/login` };
    return { link_type: LinkType.Web, url: `${this.config.websiteUrl}/${this.lang.currentLanguage()}/auth/login` };
  });
}
