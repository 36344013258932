import { Environment } from './environment.model';

export const environment: Environment = {
  productionMode: true,
  isLocal: false,
  googleTagManagerId: 'GTM-N2RNF95',
  prismicEndpoint: 'https://yallo-support.cdn.prismic.io/api/v2',
  brand: 'yallo',
  fallbackLang: 'de',
  newMicroServiceEndpoint: 'https://ms.yallo.ch',
  checkoutUrl: 'https://checkout.yallo.ch',
  allowedMsEnvs: [],
  eksMSEnvs: ['integration'],
  defaultUrlsEnvironment: '',
  yalloTvUrl: 'https://yallo.tv/',
  distributionIds: [],
  featureFlagClientKey: 'sdk-1AyWzT53SObto3SV',
  websiteUrl: 'https://www.yallo.ch',
  selfcareUrl: 'https://my.yallo.ch',
  sprinklerAppId: '6690eb6f187c274712e20ee8_app_220',
};
