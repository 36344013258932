import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { APP_ID, inject, Injectable } from '@angular/core';
import { Request } from 'express';
import { catchError, EMPTY, from, switchMap, take, throwError } from 'rxjs';
import { LanguageService } from 'language';
import { RedirectService } from 'redirect';
import { ToastService } from 'toast';
import { TranslateService } from 'translate';
import {
  App,
  ENVIRONMENT_URLS_CONFIG_TOKEN,
  EnvironmentUrlsConfig,
  ObservableCachedDataLoaderService,
  REQUEST,
  StorageKeys,
} from 'utils';
import { AuthService } from './auth.service';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  protected request = inject<Request>(REQUEST, { optional: true });
  private authService = inject(AuthService);
  private redirectService = inject(RedirectService);
  private languageService = inject(LanguageService);
  private translateService = inject(TranslateService);
  private toastService = inject(ToastService);
  private dataLoader = inject(ObservableCachedDataLoaderService);
  private config = inject<EnvironmentUrlsConfig>(ENVIRONMENT_URLS_CONFIG_TOKEN);
  private appId = inject(APP_ID, { optional: true });
  private expiredAuthErrorCodes = [
    'ERR_TOKEN_NOT_FOUND',
    'ERR_MISSING_REFRESH_TOKEN_COOKIE',
    'ERR_INVALID_TOKEN',
    'ERR_POS_AGENT_LOCKED',
    'ERR_TOKEN_IS_REVOKED',
    'ERR_INVALID_TOKEN_SCHEME',
    'ERR_MISSING_TOKEN',
    'ERR_INVALID_TOKEN_TYPE',
    'ERR_JWKS_UNAVAILABLE',
    'ERR_VERIFY_TOKEN_SIGNATURE',
  ];

  intercept(req: HttpRequest<unknown>, next: HttpHandler) {
    if (!req.url.startsWith(this.config.newMicroServiceEndpoint)) {
      return next.handle(req);
    }
    req = req.clone({
      withCredentials: true,
    });
    const ms = req.url.split(this.config.newMicroServiceEndpoint)[1].split('/')[1];
    if (['identity'].includes(ms)) {
      return next.handle(req);
    }

    const notifyLogoutAndRedirect = () => {
      this.toastService.add(
        this.translateService.getTranslation(['login', 'user_session_expired_login_modal_title']),
        false
      );
      this.redirectService.redirectTo(`${this.languageService.current}/auth/login`);
    };

    const setToken = (token: string | boolean) => {
      let newReq = req;
      if (token) {
        const headers: { [name: string]: string | string[] } = {};
        headers['Authorization'] = `Bearer ${token}`;
        newReq = req.clone({ setHeaders: headers });
      } else if (this.appId === App.pos || this.appId === App.selfcare) {
        notifyLogoutAndRedirect();
        return EMPTY;
      }
      return next.handle(newReq).pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.status === 401 && this.expiredAuthErrorCodes.includes(err?.error?.error)) {
            this.dataLoader.remove(this.appId === App.pos ? StorageKeys.Pos : StorageKeys.SelfCareToken);
            notifyLogoutAndRedirect();
            return EMPTY;
          }
          return throwError(() => err);
        })
      );
    };

    if (this.appId === App.pos) {
      return from(this.authService.getTokenPos().pipe(take(1))).pipe(switchMap(token => setToken(token)));
    }

    return from(this.authService.getToken().pipe(take(1))).pipe(switchMap(token => setToken(token)));
  }
}
