import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { PrismicDocument } from '@prismicio/client';
import { catchError, from, of, switchMap } from 'rxjs';
import { lang } from 'interfaces';
import { CmsService } from 'prismic';
import { RedirectService } from 'redirect';
import { routeSnapshotToString } from 'utils';
import { ArticleService } from '../article.service';
import { SupportArticle } from '../support-types';

export const zendeskArticleRedirect: CanActivateFn = route => {
  const redirectService = inject(RedirectService);
  const cmsService = inject(CmsService);
  const articleService = inject(ArticleService);

  const path = route.params.article;
  const id = path.split('-')[0];

  const locale = route.params.lang;
  const language = locale.split('-')[0] as lang;

  return from(cmsService.getByFieldValue('article', 'zendesk_id', id)).pipe(
    switchMap((article: PrismicDocument<SupportArticle>) => articleService.getArticlePath(article, language)),
    switchMap(newPath => {
      if (newPath === routeSnapshotToString(route)) return of(true);
      return redirectService.redirectTo(newPath);
    }),
    catchError(() => {
      return redirectService.redirectTo('/');
    })
  );
};
